import PropTypes from 'prop-types';
import React from 'react';

import { BeneFooterText, BulletUL, BulletLI } from './beneficiarySectionStyles';

function BeneFooterTextSection(props, context) {
  const {
    nothingDataStore,
    suppressBeneficiaries,
    isBeneficiariesHasNA
  } = props;

  const {
    intl: { formatMessage }
  } = context;

  const isPrimarySecodaryDeathBenefitNotPresent =
    nothingDataStore && !suppressBeneficiaries && !isBeneficiariesHasNA;

  const isPrimarySecodaryDeathBenefitPresent =
    nothingDataStore && suppressBeneficiaries;

  const isPrimaryOrSecodaryAndDeathBenefitNotPresent =
    !nothingDataStore && !suppressBeneficiaries && !isBeneficiariesHasNA;

  const isPrimaryOrSecodaryAndDBSOWithNA =
    !nothingDataStore && suppressBeneficiaries && isBeneficiariesHasNA;

  const isPrimaryOrSecodaryWithoutDBSOWithNA =
    !nothingDataStore && !suppressBeneficiaries && isBeneficiariesHasNA;

  const isPrimaryOrSecodaryAndDeathBenefitPresent =
    !nothingDataStore && suppressBeneficiaries && !isBeneficiariesHasNA;

  /* here dynamicCopy2 we checked combination when 
    1. there is no DBSO A or B present
    2. there is both DBSO A or B present and Invalid beneficiary present NA or - 
  */
  const dynamicCopy1 =
    isPrimarySecodaryDeathBenefitNotPresent ||
    isPrimaryOrSecodaryWithoutDBSOWithNA;
  const dynamicCopy2 =
    isPrimaryOrSecodaryAndDBSOWithNA || isPrimarySecodaryDeathBenefitPresent;

  return (
    <BeneFooterText>
      {dynamicCopy1 && (
        <>
          {formatMessage({
            id: 'beneCardFooterText_No_Primary_Secondary_1'
          })}
          <br />
          <br />
          {formatMessage({
            id: 'beneCardFooterText_No_Primary_Secondary_2'
          })}
        </>
      )}
      {isPrimaryOrSecodaryAndDeathBenefitPresent && (
        <>
          {formatMessage({
            id: 'beneCardFooterText_DBSO'
          })}
          <br />
          <br />
          {formatMessage({
            id: 'beneCardFooterText_No_Primary_Secondary_DBSO_2'
          })}
          <div>
            <BulletUL>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_No_Primary_Secondary_DBSO_2_List1'
                })}
              </BulletLI>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_No_Primary_Secondary_DBSO_2_List2'
                })}
              </BulletLI>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_No_Primary_Secondary_DBSO_2_List3'
                })}
              </BulletLI>
            </BulletUL>
          </div>
        </>
      )}
      {isPrimaryOrSecodaryAndDeathBenefitNotPresent && (
        <>
          {formatMessage({
            id: 'beneCardFooterText'
          })}
        </>
      )}
      {dynamicCopy2 && (
        <>
          {formatMessage({
            id: 'beneCardFooterText_No_Primary_Secondary_DBSO_1'
          })}
          <br />
          <br />
          {formatMessage({
            id: 'beneCardFooterText_DBSO_Title'
          })}
          <div>
            <BulletUL>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_DBSO_List1'
                })}
              </BulletLI>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_DBSO_List2'
                })}
              </BulletLI>
              <BulletLI>
                {formatMessage({
                  id: 'beneCardFooterText_DBSO_List3'
                })}
              </BulletLI>
            </BulletUL>
          </div>
        </>
      )}
    </BeneFooterText>
  );
}

BeneFooterTextSection.propTypes = {
  nothingDataStore: PropTypes.bool.isRequired,
  suppressBeneficiaries: PropTypes.bool.isRequired,
  isBeneficiariesHasNA: PropTypes.string.isRequired
};

BeneFooterTextSection.contextTypes = {
  intl: PropTypes.object.isRequired
};

export default BeneFooterTextSection;
